* {
  font-family: "Nunito Sans", "Arial", "sans-serif" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  scroll-behavior: auto;
  overscroll-behavior: none;
}
input:-webkit-autofill,
textarea:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-box-shadow: 0 0 0 100px rgba(60, 185, 158, 0.1) inset !important;
}
canvas {
  width: 100% !important;
}
.pac-container {
  z-index: 9999 !important;
  display: block;
}

.daterangepicker {
  color: #4b4b4b !important;
}
